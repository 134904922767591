import React from "react";
import theme from "theme";
import { Theme, Text, Link, Section, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"mymaite"} />
		<Helmet>
			<title>
				My Maite by Urantia Games
			</title>
			<meta name={"description"} content={"Maitê is a very intelligent girl, but she lives over the moon. This is why her dad  has to go there and bring her back to Earth in this 50 stages long incredible adventure."} />
			<meta property={"og:title"} content={"My Maite by Urantia Games"} />
			<meta property={"og:description"} content={"Maitê is a very intelligent girl, but she lives over the moon. This is why her dad  has to go there and bring her back to Earth in this 50 stages long incredible adventure."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/maitee.jpg?v=2021-06-02T13:55:20.265Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Menuu />
		<Section background="--color-darkL2" padding="64px 0" sm-padding="40px 0">
			<Stack>
				<StackItem width="50%" lg-width="100%">
					<Override
						slot="StackItemContent"
						margin-right="-20%"
						padding-bottom="120%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/maitee.jpg?v=2021-06-02T13:55:20.265Z) 50% 15%/cover"
						lg-margin-right="0"
						lg-padding-bottom="80%"
					/>
				</StackItem>
				<StackItem width="50%" lg-width="100%">
					<Override
						slot="StackItemContent"
						color="--light"
						mix-blend-mode="lighten"
						lg-margin-left="0"
						lg-margin-top="0"
						flex-direction="column"
						padding="98px 64px 98px 64px"
						margin-left="-20%"
						margin-top="36px"
						background="--color-greyD2"
						margin="36px 0px 0px 78px"
					/>
					<Text
						margin="0"
						font="--base"
						letter-spacing="1px"
						text-transform="uppercase"
						as="h4"
					>
						by Urantia Games
					</Text>
					<Text as="h1" margin="0 0 16px 0" font="--headline1" lg-font="--headline2">
						My Maitê
					</Text>
					<Text as="p" margin="16px 0" font="--base" max-width="400px">
						Maitê is a very intelligent girl, but she lives over the moon. This is why her dad  has to go there and bring her back to Earth in this 50 stages long incredible adventure.
						<br />
						<br />
						Jump over clouds, balloons, trampolines and into special portals in order to get to the ending of this pixel art made beautiful game.
					</Text>
					<Link
						href="https://www.nintendo.com/games/detail/my-maite-switch/"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						border-radius="2px"
						hover-background="--color-darkL1"
						padding="12px 20px 12px 20px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						text-align="center"
					>
						Nintendo Switch (America)
					</Link>
					<Link
						border-radius="2px"
						hover-background="--color-darkL1"
						href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/My-Maite-1990642.html"
						text-align="center"
						color="#F7FBFF"
						background="--color-primary"
						padding="12px 20px 12px 20px"
						margin="3px 3px 3px 3px"
						text-decoration-line="initial"
						display="inline-block"
						target="_blank"
					>
						Nintendo Switch (Europe)
					</Link>
					<Link
						background="--color-primary"
						text-decoration-line="initial"
						border-radius="2px"
						display="inline-block"
						hover-background="--color-darkL1"
						text-align="center"
						target="_blank"
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
						href="https://store-jp.nintendo.com/list/software/70010000042468.html"
						padding="12px 20px 12px 20px"
					>
						Nintendo Switch (Japan)
					</Link>
				</StackItem>
			</Stack>
		</Section>
		<Components.QuarklycommunityKitYouTube url="https://youtu.be/EF3HW1LFsLs" controls />
		<Section text-align="center" padding="10px 0 10px 0" sm-padding="40px 0">
			<Box display="flex" margin="10px 0 10px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px" width="100%">
					<Image
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0029_1.jpg?v=2021-07-05T13:57:01.707Z"
						width="100%"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0029_1.jpg?v=2021-07-05T13%3A57%3A01.707Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0029_1.jpg?v=2021-07-05T13%3A57%3A01.707Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0029_1.jpg?v=2021-07-05T13%3A57%3A01.707Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0029_1.jpg?v=2021-07-05T13%3A57%3A01.707Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0029_1.jpg?v=2021-07-05T13%3A57%3A01.707Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0029_1.jpg?v=2021-07-05T13%3A57%3A01.707Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0029_1.jpg?v=2021-07-05T13%3A57%3A01.707Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box width="100%" padding="10px">
					<Image
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0030_1.jpg?v=2021-07-05T13:57:01.706Z"
						width="100%"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0030_1.jpg?v=2021-07-05T13%3A57%3A01.706Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0030_1.jpg?v=2021-07-05T13%3A57%3A01.706Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0030_1.jpg?v=2021-07-05T13%3A57%3A01.706Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0030_1.jpg?v=2021-07-05T13%3A57%3A01.706Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0030_1.jpg?v=2021-07-05T13%3A57%3A01.706Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0030_1.jpg?v=2021-07-05T13%3A57%3A01.706Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0030_1.jpg?v=2021-07-05T13%3A57%3A01.706Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box sm-flex-direction="column" display="flex" margin="10px 0 10px 0" justify-content="space-around">
				<Box width="100%" padding="10px">
					<Image
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0041_1.jpg?v=2021-07-05T13:57:01.702Z"
						width="100%"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0041_1.jpg?v=2021-07-05T13%3A57%3A01.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0041_1.jpg?v=2021-07-05T13%3A57%3A01.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0041_1.jpg?v=2021-07-05T13%3A57%3A01.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0041_1.jpg?v=2021-07-05T13%3A57%3A01.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0041_1.jpg?v=2021-07-05T13%3A57%3A01.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0041_1.jpg?v=2021-07-05T13%3A57%3A01.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0041_1.jpg?v=2021-07-05T13%3A57%3A01.702Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px" width="100%">
					<Image
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0032_1.jpg?v=2021-07-05T13:57:01.705Z"
						width="100%"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0032_1.jpg?v=2021-07-05T13%3A57%3A01.705Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0032_1.jpg?v=2021-07-05T13%3A57%3A01.705Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0032_1.jpg?v=2021-07-05T13%3A57%3A01.705Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0032_1.jpg?v=2021-07-05T13%3A57%3A01.705Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0032_1.jpg?v=2021-07-05T13%3A57%3A01.705Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0032_1.jpg?v=2021-07-05T13%3A57%3A01.705Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/XAL03100212229-20210522-0032_1.jpg?v=2021-07-05T13%3A57%3A01.705Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
	</Theme>;
});